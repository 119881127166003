import * as React from 'react';
import PropTypes from 'prop-types';

import CRMScreen from '../../images/odoo-14-crm-5';

const CRM = ({ bgcolor }) => (
  <div className={bgcolor}>
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold leading-6 tracking-wide text-indigo-600 uppercase">
          Stay in touch with customers
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
          CRM
        </p>
        <p className="my-4 text-lg leading-7 text-gray-500">
          Track leads, close opportunities and get accurate forecasts.
        </p>
        <CRMScreen />
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Stay connected to your customers
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Real-time messaging to enhance collaboration. Get a quick
                  overview of what&apos;s going on in your team. See when a
                  colleague logs a call or sends a quote. Share ideas and files
                  in real-time and catch-up on things you missed when you were
                  away.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Get more done in less time
              </dt>
              <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Schedule activities based on your sales scripts: calls,
                  meetings, mailing, and quotations. Get all the information you
                  need, directly on the opportunity: website pages viewed, mail
                  received, etc.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Accurate forecasts
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Get the insights you need to make smarter decisions. Design
                  custom dashboards to get a picture of your business at a
                  glance. Dig deeper with real-time reports and flow charts that
                  anyone can create and share.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Real-time overview
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Easily review your performances & next activities. Know
                  exactly how you perform compared to your monthly targets.
                  Organize your work based on your scheduled next actions.
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

CRM.propTypes = {
  bgcolor: PropTypes.string,
};

CRM.defaultProps = {
  bgcolor: 'bg-white',
};

export default CRM;
